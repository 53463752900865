import { useEffect, useState } from "react";
import fileDownload from 'js-file-download';
import { Document, Packer, Paragraph,TextRun,AlignmentType, UnderlineType,HeadingLevel } from "docx";


function App() {

  const [dosyaNu, setDosyaNu] = useState('');
  const [başvurucuTc, setBaşvurucuTc] = useState('');
  const [vekilAv, setVekilAv] = useState('');


  const buttonClick = () => {

  

    const doc = new Document({
      creator: "Clippy",
      title: "Sample Document",
      description: "A brief example of using docx",
      styles: {
          paragraphStyles: [
           
              {
                  id: "aside",
                  name: "Aside",
                  basedOn: "Normal",
                  next: "Normal",
                  bold: true,
                  
                  run: {
                    
                      size: 20,
                      color: "000000",
                      italics: false,
                      bold: true,
                  },
                  paragraph: {
                      
                      indent: {
                          left: 2420,
                      },
                      spacing: {
                          line: 276,
                      },
                  },
              },
              
              {
                  id: "wellSpaced",
                  name: "Well Spaced",
                  basedOn: "Normal",
                  quickFormat: true,
                  paragraph: {
                      spacing: { line: 276, before: 1 * 7 * 0.1, after: 2 * 72 * 0.05 },
                  },
              },
             
            
          ],
      },
      numbering: {
          config: [
              {
                  reference: "my-crazy-numbering",
                  levels: [
                      {
                          level: 0,
                          format: "lowerLetter",
                          text: "%1)",
                          alignment: AlignmentType.LEFT,
                      },
                  ],
              },
          ],
      },

      sections: [{
          children: [
            
           
              new Paragraph({
                  text: "SİGORTA TAHKİM KOMİSYONU BAŞKANLIĞI’NA",
                  style: "aside",
                  bold: true,
              }),
               
              // Boşluk
              new Paragraph({ text: "", style: "wellSpaced", }),
              new Paragraph({ text: "", style: "wellSpaced", }),
              
              // Dosya No
              new Paragraph({
                  children: [
                      new TextRun({
                          text: "DOSYA NO                    :",
                          bold: true,
                          underline: {},
                        
                      }),

                      new TextRun( {
                            text: dosyaNu,
                      }),
                  ],
              }),
            
              new Paragraph({ text: "", style: "wellSpaced", }),
             
              // Altı Çizgili Yer
              new Paragraph({
                children: [
                    new TextRun({
                        text: "SİGORTA ŞİRKETİ	     :",
                        bold: true,
                        underline: {},
                    }),

                    new TextRun( {
                          text:"  TÜRKİYE SİGORTA A.Ş. (4340056984) Büyükdere Cad. Güneş Plaza No:110 34394 Esentepe, Şişli/İstanbul  ",
                    }),
                ],
            }),
         
               
              new Paragraph({ text: "", style: "wellSpaced", }),
 
              // Altı Çizgili Yer
              new Paragraph({
                children: [
                    new TextRun({
                        text: "VEKİLİ                          :",
                        bold: true,
                        underline: {},
                    }),
                    new TextRun( {
                          text:" Av. İsmail Öztürk ",
                    }),
                ],
              }),
         
              new Paragraph({ text: "", style: "wellSpaced", }),
              
              // Altı Çizgili Yer
              new Paragraph({
                children: [
                    new TextRun({
                        text: "BAŞVURUCU                :",
                        bold: true,
                        underline: {},
                    }),
                    new TextRun( {
                          text: başvurucuTc,
                    }),
                ],
              }),

              new Paragraph({ text: "", style: "wellSpaced", }),

              new Paragraph({
                children: [
                    new TextRun({
                        text: "VEKİLİ                          :",
                        bold: true,
                        underline: {},
                    }),
                    new TextRun( {
                          text: vekilAv,
                    }),
                ],
              }),

              new Paragraph({ text: "", style: "wellSpaced", }),

              new Paragraph({
                children: [
                    new TextRun({
                        text: "KONU                             :",
                        bold: true,
                        underline: {},
                    }),
                    new TextRun( {
                          text:" Başvurucunun haksız ve hukuki mesnetten yoksun başvurusuna       karşı cevaplarımızın sunulmasından ibarettir. ",
                    }),
                ],
              }),
         
         
         
           ],
      }],
  });    


   
  Packer.toBlob(doc).then(blob => {
      fileDownload(blob, 'inputs.docx');
    });
  }


  return (

    <div className="h-screen w-screen flex flex-col">
      <header className="bg-gray-800 text-white text-center p-4">
        <h1 className="text-2xl">UYANIK HD</h1>
      </header>

      <main className="w-full h-full bg-slate-300 flex flex-col items-center justify-center">
         {/* Input Area */}
          <div className="flex flex-col w-fit gap-4 bg-slate-200 px-24 rounded-lg py-32 justify-center p-4">
           <div>
             <p className="text-sm">Dosya Nu</p>
             <input onChange={(e)=>{ setDosyaNu(e.target.value)}}  type="text" className="p-2 border border-gray-300 rounded-lg" />
           </div>
           
           <div>
             <p className="text-sm">Başvurucu TC</p>
             <input onChange={(e)=>{ setBaşvurucuTc(e.target.value)}}  type="text" className="p-2 border border-gray-300 rounded-lg" />
           </div>

           <div>
             <p className="text-sm">Vekil Avukat</p>
             <input onChange={(e)=>{ setVekilAv(e.target.value)}}  type="text" className="p-2 border border-gray-300 rounded-lg" />
           </div>
           
            <button onClick={buttonClick} className="bg-blue-500 text-white p-2 ml-2 rounded-full">Create</button>
          </div>
      </main>
    
    </div>
  );
}

export default App;
